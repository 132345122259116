import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import English from "./assets/translations/en-en.json";
import Portuguese from "./assets/translations/pt-br.json"

const resources = {
  English,
  Portuguese,
}

export const availableLanguages = Object.keys(resources)

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    defaultNS: "common",
    fallbackLng: "Portuguese",
  });