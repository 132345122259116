import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

function PeoplePortfolioItem(props: any) {
    return <>
        <h2 className="KumbhSans-Bold600">{props.title}</h2>
        <p className="card-people-subtitle KumbhSans-Regular100">{props.subtitle}</p>
        <p className='KumbhSans-Regular100 font-size-xs'>{props.description}</p>
    </>
}

function PeopleServicesAreaComponent(props: any) {
    const {t, i18n} = useTranslation('homePage')
    return <>
        <div id="portfolio-people">
            <h1 id="services-portfolios-title" className="KumbhSans-Bold900">{t('services-title-text')}</h1>
            <p id="services-portfolios-subtitle" className='KumbhSans-Regular100 font-size-s'>{t('people-introText-subtitle')}</p>
            <div id="portfolio-people-header">
                <p className='KumbhSans-Bold400 font-size-medium'>{t('people-introText-1')}</p>
                <p className='KumbhSans-Bold400 font-size-medium'>{t('people-introText-2')}</p>
                <p className='KumbhSans-Bold400 font-size-medium'>{t('people-introText-3')}</p>
                <p className='KumbhSans-Bold400 font-size-medium'>{t('people-introText-4')}</p>
            </div>
            <div id="portfolio-people-footer">
                <span className='KumbhSans-Regular100 font-size-s'>{t('services-link-intro')}</span>
                <a href="/gov">{t('services-link')}</a>
            </div>
        </div>
    </>
}

export default PeopleServicesAreaComponent;