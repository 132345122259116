import React, {useState } from 'react';
import './style.css';

export function Social() {
    return (
        <div className="cont-social hide-if-small">
        <div className="social">
            <a href="https://www.linkedin.com/company/lab-of-codes-solucoes-digitais" target="_blank" className="linkedin social-item">
                <div className='linkedin'></div>
            </a>
        </div>
        </div>
    )
}