import React from 'react';
import './style.css';

import logo1 from '../../../assets/images/logo/clients/cesusc.png';
import logo2 from '../../../assets/images/logo/clients/creasc.png';
import logo9 from '../../../assets/images/logo/clients/creapr.png';
import logo3 from '../../../assets/images/logo/clients/softplan.png';
import logo4 from '../../../assets/images/logo/clients/meta.png';
import logo5 from '../../../assets/images/logo/clients/sinqia.png';
import logo6 from '../../../assets/images/logo/clients/nsc.png';
import logo7 from '../../../assets/images/logo/clients/spintec.png';
import logo8 from '../../../assets/images/logo/clients/target.png';
import { useTranslation } from 'react-i18next';

function LogoItem(props: any) {
    return <>
        <div className="item"><a href={props.link} target="_blank"><img src={props.logo} /></a></div>
    </>
}

function ClientsAreaComponent(props: any) {

    const {t, i18n} = useTranslation('homePage');
    let logos: any[] = [
        { link: 'https://www.cesusc.edu.br/', logo: logo1 },
        { link: 'https://portal.crea-sc.org.br/', logo: logo2 },
        { link: 'https://www.crea-pr.org.br/', logo: logo9 },
        { link: 'https://www.softplan.com.br/', logo: logo3 },
        { link: 'https://www.meta.com.br/', logo: logo4 },
        { link: 'https://www.sinqia.com.br/', logo: logo5 },
        { link: 'https://www.nsccomunicacao.com.br/marcas-nsc/nsc-tv/', logo: logo6 },
        { link: 'https://pulsati.com.br/', logo: logo7 },
        { link: 'https://www.targetsolucoes.com.br/site/', logo: logo8 }];

    return <>
        <div id="clients">
            <div id="clients-container-text"><span className='KumbhSans-Regular500 font-size-s'>{t('clients-intro-text')}</span></div>
            <div id="clients-container">
                <div id="clients-container-left-arrow"></div>
                <div id="clients-container-logos">
                    {logos.map(item =>
                        <LogoItem link={item.link} logo={item.logo} />)}
                </div>
                <div id="clients-container-right-arrow"></div>
            </div>
        </div>
    </>
}

export default ClientsAreaComponent;