import React from 'react';
import './style.css';

import icon1 from '../../../assets/images/icons/icon1.png';
import icon2 from '../../../assets/images/icons/icon2.png';
import icon3 from '../../../assets/images/icons/icon3.png';
import icon4 from '../../../assets/images/icons/icon4.png';
import icon5 from '../../../assets/images/icons/icon5.png';
import { useTranslation } from 'react-i18next';

function AdvantagesItem(props: any) {
    return <>
        <div className="item">
            <img src={props.icon} />
            <span>{props.name}</span>
        </div>
    </>
}

function AdvantagesAreaComponent(props: any) {

    const {t, i18n} = useTranslation('homePage');
    let items: any[] = [
        { icon: icon1, name: t('advantages-item1') },
        { icon: icon2, name: t('advantages-item2') },
        { icon: icon3, name: t('advantages-item3') },
        { icon: icon4, name: t('advantages-item4') },
        { icon: icon5, name: t('advantages-item5') }]

    return <>
        <div id="differentials">
            <div id="differentials-items" className='KumbhSans-Bold600 font-size-base'>
                {items.map(item => <AdvantagesItem icon={item.icon} name={item.name} />)}
            </div>
        </div>
    </>
}

export default AdvantagesAreaComponent;