import { render } from '@testing-library/react';
import React from 'react'
import './style.css';
import logo from '../../assets/images/logo/logo-big_220x86_1.png';
import imgInovar from '../../assets/images/events/inovar.jpg';
import FooterMC from '../../components/macro/Footer';
import HeaderMC from '../../components/macro/Header';
import {useTranslation} from "react-i18next";

function CabecalhoEventos() {
    const {t, i18n} = useTranslation('gov')
    return (
        <>
            <div className="b-example-divider"></div>
            <div className="bg-color-grey-5 text-secondary px-4 py-5 text-center">
                <div>
                    <h1 className="display-5 fw-bold text-white">Eventos, Palestras e Podcasts</h1>
                    <div className="col-lg-6 mx-auto">
                    <p className="fs-3 mb-1">Fique por dentro das novidades: acompanhe nossas últimas participações na comunidade.</p>
                    
                    <div className="mt-4">
                        <a className="btn btn-secondary bg-color-grey-3 btn-lg px-4 me-sm-3" href="/">Voltar p/ Home</a>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Feature0() {
    const {t, i18n} = useTranslation('gov')
    return (
        <div className='container py-5'>
            <div className="row featurette">
                <div className="col-md-6">
                    {/* <h1 className="display-5 fw-bolder text-dark">4º Inovar Confea <span className="text-muted">Não perca! Dia 29/11!</span></h1> */}
                    <h1 className="display-5 fw-bolder text-dark">4º Inovar Confea</h1>
                    <h2 className="px-3 featurette-heading">Transformação Digital e Inovação</h2>
                    <p className="px-3 lead">Em evento promovido pelo sistema Confea, nosso CEO e especialista em Inovação e Transformação Digital, Rodrigo Bianchetti, falou sobre a transformação digital e inovação para o público do sistema Confea.</p>
                </div>
{/*                 <div className="col-md-6">    
                    <div className="hide-if-small mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <img src={imgInovar} className="img-fluid" width="560" height="315" loading="lazy"/>
                    </div>
                    <div className="hide-if-big mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <img src={imgInovar} className="img-fluid" width="560" height="315" loading="lazy"/>
                    </div>
                </div> */}
                <div className="col-md-6">
                   <div className="hide-if-small mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/hDk9toqpSIs?si=WXRI4N-4X3MLofa7" title="YouTube video player" allow-data="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                    <div className="hide-if-big mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <iframe width="400" height="280" src="https://www.youtube.com/embed/hDk9toqpSIs?si=WXRI4N-4X3MLofa7" title="YouTube video player" allow-data="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

function Feature1() {
    const {t, i18n} = useTranslation('gov')
    return (
        <div className='container py-5'>
            <div className="row featurette">
                <div className="col-md-6">
                    <h1 className="display-5 fw-bolder text-dark">Festival de Aprendizado</h1>
                    <h2 className="px-3 featurette-heading">Capacidades para Inovação</h2>
                    <p className="px-3 lead">Em evento promovido pela WeGov e sistema Confea, no dia 27/10/2023, nosso CEO e especialista em Inovação e Transformação Digital, Rodrigo Bianchetti, falou sobre os pilares da transformação digital e sua relação com a construção de capacidades para inovar.</p>
                </div>
                <div className="col-md-6">
                   <div className="hide-if-small mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ppREAPI-A4M" title="YouTube video player" allow-data="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                    <div className="hide-if-big mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <iframe width="400" height="280" src="https://www.youtube.com/embed/ppREAPI-A4M" title="YouTube video player" allow-data="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Feature2() {
    const {t, i18n} = useTranslation('gov')
    return (
        <div className='container py-5'>
            <div className="row featurette">
                <div className="col-md-6">
                    <h1 className="display-5 fw-bolder text-dark">BRDE Labs</h1>
                    <h2 className="px-3 featurette-heading">Inovação no Setor Público</h2>
                    <p className="px-3 lead">Em evento do BRDE Labs, promovido pela ACATE (Associação Catarinense de Tecnologia), um bate-papo com o superintendente de Governo Eletrônico de Florianópolis (Marcos Lichtblau), Rodrigo Bianchetti questiona como a cidade de Florianópolis vem transformando a experiência do cidadão e dos serviços públicos de forma geral.</p>
                </div>
                <div className="col-md-6">
                    
                <div className="hide-if-small mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/MnbDdspmt1c" title="YouTube video player" allow-data="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                    <div className="hide-if-big mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <iframe width="400" height="280" src="https://www.youtube.com/embed/MnbDdspmt1c" title="YouTube video player" allow-data="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Feature3() {
    const {t, i18n} = useTranslation('gov')
    return (
        <div className='container py-5'>
            <div className="row featurette">
                <div className="col-md-6">
                    <h1 className="display-5 fw-bolder text-dark">Webinar GovCast</h1>
                    <h2 className="px-3 featurette-heading">Transformação Digital e Inovação no CREA-SC</h2>
                    <p className="px-3 lead">O Webinar GovCast é uma iniciativa do Grupo de Trabalho de Transformação Digital da Vertical Smart Cities da ACATE em parceria com o CREA-SC. Neste episódio, Rodrigo Bianchetti, que é Coordenador do GT TD e CEO da Lab of Codes, conversa com o Presidente do CREA-SC, Kita Xavier, com o Assessor de Planejamento e Qualidade do CREA-SC, Rhuan Bittencourt, e com o Coordenador da Área de Inovação, Rodrigo Espindola, sobre a jornada de transformação digital da instituição.</p>
                </div>
                <div className="col-md-6">
                    
                <div className="hide-if-small mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/mhzDLYnmxVI?si=CumbsIYXfqNoc31h" title="YouTube video player" allow-data="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                    <div className="hide-if-big mb-5 col-sm-12 col-lg-6 col-md-6 col-xs-6 text-center">
                        <iframe width="400" height="280" src="https://www.youtube.com/embed/mhzDLYnmxVI?si=CumbsIYXfqNoc31h" title="YouTube video player" allow-data="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}



export class LandingGovPage extends React.Component {    
    render() {
        return (
            <>
                <header>
                    <HeaderMC logo={logo} />
                </header>
                <main>
                    <CabecalhoEventos/>
                    <Feature0/>
                    <Feature1/>
                    <Feature2/>
                    <Feature3/>
                </main>
                <footer>
                    <FooterMC />
                </footer>
            </>
        );
    }
}