import React, {useState } from 'react';
import './style.css';
import {useTranslation} from "react-i18next";

function Footer(props: any) {
    const {t, i18n} = useTranslation('footer')
    return <>
        <div id="footer">
            <span className='KumbhSans-Regular500 font-size-s' id="copyright">{t('copyright-text')}</span>
        </div>
    </>
}

export default Footer;