import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CareersPage, HireUsPage } from "./pages";
import HomePage from "./pages/HomePage";
import { LandingGovPage } from "./pages/LandingGov";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
{/*     <Route path="/hire-us" element={<HireUsPage/>} />
        <Route path="/careers" element={<CareersPage/>} />
        <Route path="/careers/register" element={<HomePage/>} />
        <Route path="/gov" element={<LandingGovPage/>} /> */}
        <Route path="/eventos" element={<LandingGovPage/>} />
        {/* <Route path="/logon" element={<HomePage/>} /> */}
      </Routes>
    </Router>
  );
}

export default AppRoutes;