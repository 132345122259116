import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

function SectorItem(props: any) {
    return <>
        <span className="KumbhSans-Bold600">{props.title}</span>
    </>
}

function AboutAreaComponent(props: any) {
    const {t, i18n} = useTranslation('homePage')
    return <>
        <div id="about">
            <div id="about-left" className='hide-if-small'>
                <div id="about-left-rectangule"></div>
            </div>
            <div id="about-content">
                <div id="about-content-left">
                    <h1 className="KumbhSans-Bold900 font-size-display">{t('about-title')}</h1>
                    <span className="KumbhSans-Regular100 font-size-base">{t('about-description')}</span>
                </div>
                <div id="about-content-right" className='hide-if-small'>
                    <span id="about-content-right-top-text" className='KumbhSans-Regular100 font-size-s'>{t('about-we-are-in')}</span>
                    <div id="about-content-right-sectors">
                        <div id="about-content-right-sector-techs">
                            <SectorItem title={t('about-item1')} />
                        </div>
                        <div id="about-content-right-sector-industry">
                            <SectorItem title={t('about-item2')} />
                        </div>
                        <div id="about-content-right-sector-gov">
                            <SectorItem title={t('about-item3')} />
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-right" className='hide-if-small'>
                <div id="about-right-rectangule"></div>
            </div>
        </div>
    </>
}

export default AboutAreaComponent;