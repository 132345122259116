import { useTranslation } from 'react-i18next';
import DigitalServicesAreaComponent from './DigitalServicesArea';
import PeopleServicesAreaComponent from './PeopleServicesArea';
import './style.css'

function ServicesAreaComponent(props: any) {
    const {t, i18n} = useTranslation('homePage')
    return <>
        <div id="services">
            <div id="services-portfolios">
                <PeopleServicesAreaComponent />
                <DigitalServicesAreaComponent />
            </div>
        </div>
    </>
}

export default ServicesAreaComponent;