import React, {useState } from 'react';
import './style.css';
import {useTranslation} from "react-i18next";
import {availableLanguages} from "../../../i18n";
import FloatingWhatsApp from 'react-floating-whatsapp';
import { Social } from '../Social';

function Header(props: any) {
    const {t, i18n} = useTranslation('header')
    return <>
        <div id="top-menu">
            <div id="hamburguer-menu"></div>
            <div id="menu">
                <div id="logo"><a className="navbar-brand" href="/"><img src={props.logo} className="image" /></a></div>
                <div id="top-menu-items" className='KumbhSans-Regular500'>
                </div>
                <button id="hire-us-button" className="KumbhSans-Bold900" onClick={() => window.location.href = 'mailto:hello@labofcodes.com'}>{t('phone-number')} | hello@labofcodes.com</button>
            </div>
            <div>
            <FloatingWhatsApp
                phoneNumber="+351913941517"
                accountName="Lab of Codes"
                statusMessage={t('whatsapp-statusMessage')}
                chatMessage={t('whatsapp-chatMessage')}
                placeholder={t('whatsapp-placeholder')}
                allowClickAway
                notification
                notificationDelay={60000}
            />
            <Social/>
            </div>
{/*             <select id="language-selector" defaultValue={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)}>
                {availableLanguages.map((language) => (
                    <option key={language} value={language}>{t(`${language}`)}</option>
                ))}
            </select> */}
        </div>
    </>
}

export default Header;