import React from 'react';
import { useTranslation } from 'react-i18next';
import imgLogoGovCoe from '../../../assets/images/logo/xcoe/govcoebr-black.png';
import imgLogoGovCoe2 from '../../../assets/images/logo/xcoe/govcoebr-white.png';
import './style.css';

import flags from '../../../assets/images/others/flags.png';
import Modal from '../../../components/micro/Modal';

function WelcomeAreaComponent(props: any) {

    const {t, i18n} = useTranslation('header');
    return <>
        <div id="slideshow" className='py-0 p-1'>
            <div id="slide1" className='hide-if-small'></div>{/* 
            <div id="slide2" className='hide-if-small'>
                <div><span>{t('welcome-slide3-flagstext')}</span></div>
                <img src={imgLogoGovCoe} className="img-fluid" width="200" loading="lazy"/>
                <h2 className='KumbhSans-Bold900 mt-5'>{t('welcome-slide2-title')}</h2>
                <div id="slide2-text">
                    <p className='KumbhSans-Regular100 font-size-medium'>{t('welcome-slide2-text')}</p>
                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                        <a className="btn btn-primary btn-lg px-4 me-sm-3 bg-color-yellow" href="/gov">{t('welcome-slide2-more')}</a>
                    </div>
                </div>
            </div> */}
            <div id="slide3">
                <h2 className='KumbhSans-Bold900'>{t('welcome-slide3-slogan')}</h2>
                <p className='KumbhSans-Regular100 font-size-medium'>{t('welcome-slide3-1-text')}</p>
                <p className='KumbhSans-Regular100 font-size-medium'>{t('welcome-slide3-2-text')}</p>
                <div className="d-grid gap-3 d-sm-flex my-3">
                    <a className="btn btn-secondary bg-color-grey-3 btn-lg px-4 me-sm-3" href="#jornada">{t('welcome-slide3-more-btn')}</a>
                    <a className="btn btn-secondary bg-color-grey-3 btn-lg px-4 me-sm-3" href="/eventos">{t('welcome-slide2-btn')}</a>
                </div>
                
            </div>
            <div id="slide4" className='hide-if-small'></div>
        </div>
    </>
}

export default WelcomeAreaComponent;