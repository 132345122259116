import { render } from '@testing-library/react';
import React from 'react'
import './style.css';

import logo from '../../assets/images/logo/logo-big_220x86_1.png';

import imgJornada from '../../assets/images/journey/transformacaodigefetiva.png';
import HeaderMC from '../../components/macro/Header';
import WelcomeAreaComponent from './WelcomeArea';
import AboutAreaComponent from './AboutArea';
import ServicesAreaComponent from './ServicesArea';
import AdvantagesAreaComponent from './AdvantagesArea';
import ClientsAreaComponent from './ClientsArea';
import FooterMC from '../../components/macro/Footer';

function Overview() {
    return (
        <>
        <div id="jornada" className="px-4 pt-3 my-3 text-center">
            <h1 className="display-4 fw-bold">Jornada de Transformação</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-2">A jornada de transformação digital da Lab acontece da seguinte forma:</p>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                
                </div>
            </div>
            <div className="overflow-hidden">
                <div className="container px-5">
                    <img src={imgJornada} className="img-fluid" alt="Colméia de Serviços" loading="lazy"/>
                </div>
            </div>
        </div>
        </>
    )
}

function FraseCitacao() {
    return (
        <div className="">
            <div className="container px-5 my-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-10 col-xl-7">
                        <div className="text-center">
                            <div className="fs-4 mb-4 fst-italic">"Em todas as etapas da jornada são incluídas ações de capacitação e mentoria, além da disponibilização de materiais digitais."</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function News() {
    return (
        <>
            <div className="b-example-divider"></div>

            <div className="text-secondary px-4 py-1 text-center">
            <div className="py-1">
                <div className="mx-auto">
                
                <div className="container px-1">
                    <img src={imgJornada} className="img-fluid" alt="Colméia de Serviços" loading="lazy"/>
                </div>
            </div>
            </div>
            </div>

            
        </>
    );
}

function HomePage() {
    return (
        <div>
            <header>
                <HeaderMC logo={logo} />
                <WelcomeAreaComponent />
            </header>
            <main>
                <AboutAreaComponent />
                <AdvantagesAreaComponent />
                <ServicesAreaComponent />
                <Overview/>
                <FraseCitacao/>
                <ClientsAreaComponent />
            </main>
            <footer>
                <FooterMC />
            </footer>
        </div>
    );
}

export default HomePage;