import React, {ChangeEvent} from 'react';
import AppRoutes from "./AppRoutes";
import './assets/dist/css/bootstrap.min.css';
import './App.css';

function App() {

  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;