import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

function DigitalPortfolioItem(props: any) {
    return <>
        <h2 className="KumbhSans-Bold600">{props.title}</h2>
        <p className="KumbhSans-Regular100 font-size-medium">{props.subtitle}</p>
        <p className='KumbhSans-Regular100 font-size-s'>{props.description}</p>
    </>
}

function DigitalServicesAreaComponent(props: any) {
    const {t, i18n} = useTranslation('homePage')
    return <>
        <div id="portfolio-digital">
            <div id="portfolio-digital-header">
                <h2 className='KumbhSans-Bold600'>./Serviços</h2>
            </div>
            <div id="portfolio-digital-cards">
                <div id="card-enterprise-design">
                    <DigitalPortfolioItem 
                        title={t('digital-eDesignTitle')} 
                        subtitle={t('digital-eDesignSentence')}
                        description={t('digital-eDesignDescription')} />
                </div>
                <div id="card-service-design">
                    <DigitalPortfolioItem 
                        title={t('digital-sDesignTitle')} 
                        subtitle={t('digital-sDesignSentence')}
                        description={t('digital-sDesignDescription')} />
                </div>
                <div id="card-product-design">
                    <DigitalPortfolioItem 
                        title={t('digital-pDesignTitle')} 
                        subtitle={t('digital-pDesignSentence')}
                        description={t('digital-pDesignDescription')} />
                </div>
                <div id="card-data-design">
                    <DigitalPortfolioItem 
                        title={t('digital-dDesignTitle')} 
                        subtitle={t('digital-dDesignSentence')}
                        description={t('digital-dDesignDescription')} />
                </div>
            </div>
            <div id="portfolio-digital-footer">
                <span className='KumbhSans-Regular100 font-size-s'></span>
            </div>
        </div>
    </>
}

export default DigitalServicesAreaComponent;